import { render, staticRenderFns } from "./CreditApp.vue?vue&type=template&id=65cd98d5&scoped=true&"
import script from "./CreditApp.vue?vue&type=script&lang=js&"
export * from "./CreditApp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65cd98d5",
  null
  
)

export default component.exports